.somethingwentwrong{
    height: 100vh;
    width: 100vw;

    background-image: url("../../assets/gameAssets/bg.jpg");
    background-position: center top;
    background-size: cover;

    display: grid;
    place-content: center;

    h1{
        margin-top: calc(10vw + 5vh);
        color: white;
        text-align: center;
        font-size: calc(3vh + 3vw);
    }

    .somethingwentwrong__btn{
          text-align: center;
         font-size: calc( 1vh + 1.5vw);
        font-weight: 800;
        border-radius: calc( .8vh + .8vw);
        border: none;
        min-width: calc( 10.5vh + 10.5vw);
        max-width: calc( 15.5vh + 15.5vw);
        padding: calc( .4vh + .4vw) calc(.6vh + .6vw);
        background-color: #1AA1E6;
        color: #fff;
        cursor: pointer;
        &:hover{
                background-color: #BFF2F8;
                color: #1AA1E6;
        }
        margin: calc(1vh + 1vw) auto;
    }

}