.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
  // background-color: #1a94b4;

  h2 {
    // color: #f4bc13;
    // margin-top: 5rem;
    font-size: 4rem;
    text-align: center;
  }
}
